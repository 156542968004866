import { Link } from 'react-router-dom';
import './style.scss';
import News from '../../components/News';
import Contact from '../../components/Contact';
import Faq from '../../components/Faq';

import header from '../../assets/firststepheader.webp';
import { Helmet } from 'react-helmet';

const FirstStep = () => {
  return (
    <>
      <Helmet>
        <title>Prvi korak | Mentalni Kompas</title>
      </Helmet>

      <div className="firstStepPage">
        <header>
          <img src={header} alt="Savjetovanje - prvi korak" />
          <div className="overlay"></div>
          <h1>
            Napravi prvi korak <br /> ka savjetovanju
          </h1>
        </header>

        <section>
          <div className="quote">
            <h1>
              Učiniti prvi korak ka mentalnom zdravlju može biti zastrašujuće,
              ali mi smo ovdje da vas vodimo kroz taj proces.
            </h1>
          </div>
        </section>

        <section>
          <div className="reasons">
            <div className="container">
              <h1>Razlozi za savjetovanje</h1>
            </div>
            <div className="container">
              <p>
                Savjetovanje može pomoći u nizu situacija — od anksioznosti i
                depresije do problema u međuljudskim odnosima i stresu na poslu.
                Važno je znati da niste sami i da postoji stručna pomoć koja
                može olakšati vaš put ka boljem mentalnom zdravlju.
              </p>
            </div>
          </div>
        </section>

        <section>
          <div className="request">
            <h1>Rezervacija termina</h1>
            <div className="grid">
              <div className="container">
                <h2>1.</h2>
                <p>odaberite uslugu koja Vam najviše odgovara</p>
              </div>
              <div className="container">
                <h2>2.</h2>
                <p>kontaktirajte nas za dogovor oko konzultacija</p>
              </div>
              {/* <div className="container">
              <h2>3.</h2>
              <p>
                besplatna online konzultacija na kojoj utvrđujemo Vaše ciljeve
              </p>
            </div> */}
              <div className="container">
                <h2>3.</h2>
                <p>potvrdite termin savjetovanja</p>
              </div>
            </div>
            {/* <Link to="/">želim konzultacije</Link> */}
          </div>
        </section>

        <Faq />

        <News />

        <Contact />
      </div>
    </>
  );
};

export default FirstStep;
