import { BrowserRouter } from 'react-router-dom';
import Router from './routes';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import ReactGA from 'react-ga4';

const App = () => {
  const TRACKING_ID = 'G-V9BMJK9RKC';
  ReactGA.initialize(TRACKING_ID);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Router />
    </BrowserRouter>
  );
};

export default App;
