import { Link, Outlet } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CookieConsent from 'react-cookie-consent';

const MainLayout = () => {
  return (
    <>
      <Navbar />
      <Outlet />
      <CookieConsent
        buttonText="Prihvati"
        style={{
          background: 'var(--quaternary)',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
        buttonStyle={{
          color: 'var(--quaternary)',
          backgroundColor: 'var(--primary)',
          fontSize: '0.9rem',
          textTransform: 'uppercase',
          borderRadius: 'var(--radius)',
          padding: '0.5rem 1rem',
        }}
        expires={150}
      >
        Ova web stranica koristi kolačiće kako bi se osiguralo bolje korisničko
        iskustvo. Više informacija pronađite <Link to="/kolacici">ovdje</Link>.{' '}
      </CookieConsent>
      <Footer />
    </>
  );
};

export default MainLayout;
