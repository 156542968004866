import Faq from '../../components/Faq';
import News from '../../components/News';
import './style.scss';

import header from '../../assets/servicesHeader.webp';
import business from '../../assets/naslovna4.webp';
import online from '../../assets/naslovna3.webp';
import one from '../../assets/naslovna2.webp';
import Contact from '../../components/Contact';
import { Helmet } from 'react-helmet';
import jasmina1 from '../../assets/jasmina1.jpeg'
import jasmina3 from '../../assets/jasmina3.jpg'

const ServicesPage = () => {
  return (
    <>
      <Helmet>
        <title>Usluge | Mentalni Kompas</title>
      </Helmet>

      <div className="servicesPage">
        <header>
          <div className="backgroundImage">
            <img src={header} alt="Mentalno savjetovanje" />
          </div>
          <div className="overlay"></div>
          <div className="content">
            <h1>
              Vaša emocionalna i psihološka dobrobit su naša glavna misija
            </h1>
          </div>
        </header>
        <section>
          <div className="services">
            <h1>Naše usluge</h1>
            <div className="container">
              <div className="image">
                <img src={jasmina1} alt="Individualno savjetovanje" />
              </div>
              <div className="content">
                <h3>Individualno savjetovanje</h3>
                <p>
                  Individualni pristup za rješavanje osobnih problema i izazova.
                </p>
                <p>€45 / 60min</p>
              </div>
            </div>
            <div className="container">
              <div className="content">
                <h3>Online savjetovanje</h3>
                <p>Terapeutski razgovori iz udobnosti vašeg doma.</p>
                <p>€45 / 60min</p>
              </div>
              <div className="image">
                <img src={jasmina3} alt="Online savjetovanje" />
              </div>
            </div>
            <div className="container">
              <div className="image">
                <img src={business} alt="Poslovno savjetovanje" />
              </div>
              <div className="content">
                <h3>Poslovno savjetovanje</h3>
                <p>Osnaživanje kroz grupnu dinamiku i zajedničko iskustvo.</p>
                <p>€55 / 60min</p>
              </div>
            </div>
            {/* <div className="container">
            <div className="content">
              <h3>Online konzultacije</h3>
              <p>Terapeutski razgovori iz udobnosti vašeg doma.</p>
              <p>cijene i trajanje</p>
            </div>
            <div className="image">
              <img
                src="https://img.freepik.com/free-photo/close-up-girl-girl-learning-online_23-2149014046.jpg?w=740&t=st=1693576666~exp=1693577266~hmac=63b68037b1fea8bcb2c560315bd87e4a4a233f3377d7e8e380ce79c5c9705a85"
                alt=""
              />
            </div>
          </div> */}
          </div>
        </section>
        <Faq />
        <News />
        <Contact />
      </div>
    </>
  );
};

export default ServicesPage;
