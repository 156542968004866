import React from 'react';
import { Link } from 'react-router-dom';

const Page404 = () => {
  return (
    <div
      className="container"
      style={{
        height: '90vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap:'var(--gap)'
      }}
    >
      <h1 style={{ fontSize: '3rem' }}>404 :(</h1>
      <h2>Stranica nije pronađena.</h2>
      <Link
        to="/"
        style={{
          backgroundColor: 'var(--primary)',
          padding: '0.5rem 2rem',
          borderRadius: '5px',
          color: 'white',
        }}
      >
        Početna
      </Link>
    </div>
  );
};

export default Page404;
