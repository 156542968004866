import { Link } from "react-router-dom"

const MessageSentPage = () => {
  return (
    <div className='messsageSentPage'>
      <h1>Poruka je poslana!</h1>
      <Link to='/'>Početna</Link>
    </div>
  )
}

export default MessageSentPage