import { useRoutes } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import HomePage from '../pages/HomePage';
import ServicesPage from '../pages/ServicesPage';
import AboutUs from '../pages/AboutUs';
import FirstStep from '../pages/FirstStep';
import NewsPage from '../pages/NewsPage';
import ArticlePage from '../pages/ArticlePage';
import Article1 from '../pages/ArticlePage/Articles/Article1';
import MessageSentPage from '../pages/MessageSentPage';
import PrivacyPage from '../pages/PrivacyPage';
import CookiesPage from '../pages/CookiesPage';
import Article2 from '../pages/ArticlePage/Articles/Article2';
import Page404 from '../pages/Page404/Page404';

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { index: true, element: <HomePage /> },
        { path: 'usluge', element: <ServicesPage /> },
        { path: 'o-nama', element: <AboutUs /> },
        { path: 'prvi-korak', element: <FirstStep /> },
        {
          path: 'novosti',
          children: [
            { index: true, element: <NewsPage /> },
            // { path: ':slug', element: <ArticlePage /> },
            { path: 'kako-prepoznati-anksioznost-simptomi-i-rjesenja', element: <Article1 /> },
            { path: 'ucinkovite-tehnike-opustanja-savjeti-za-bolje-mentalno-zdravlje', element: <Article2 /> },
          ],
        },
        {path: 'poruka-poslana', element: <MessageSentPage/>},
        {path: 'politika-privatnosti', element: <PrivacyPage/>},
        {path: 'kolacici', element: <CookiesPage/>},
        {
          path: '*',
          element: <Page404 />,
        },
      ],
    },
  ]);
}
