import News from '../../components/News';
import './style.scss';
import header from '../../assets/naslovna1.webp';
import { Helmet } from 'react-helmet';

import jasmina1 from '../../assets/jasmina4.jpg';

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>O meni | Mentalni Kompas</title>
      </Helmet>
      <div className="aboutUs">
        <header>
          <img src={header} alt="Mentalni kompas" />
          <h1>Mjesto gdje je vaše mentalno zdravlje na prvom mjestu</h1>
          <div className="overlay"></div>
        </header>

        <section>
          <div className="about">
            <div className="imageDesktop">
              <img src={jasmina1} alt="O meni" />
            </div>
            <div className="content">
              <h1>O meni</h1>
              <div className="imageMobile">
                <img src={jasmina1} alt="O meni" />
              </div>
              <p>
                Ja sam Jasmina i zalažem za mentalno zdravlje i dobrobit. Od
                2018. godine aktivno sam uključena u zdravstveni sustav, gdje se
                kontinuirano susrećem s izazovima mentalnog zdravlja među
                odraslima, roditeljima i zdravstvenim osobljem. Ova iskustva
                potaknula su me na dodatnu edukaciju i na uspostavu
                individualnog savjetovališta u kojem svaka osoba može pronaći
                sigurno utočište, bez straha od osude svojih emocija ili
                problema.
              </p>
              <p>
                Profesionalno sam članica UTA-e (Udruge transakcijske analize
                Hrvatska), kao i međunarodnih organizacija EATA-e. Kontinuirano
                prisustvujem predavanjima, konferencijama i edukacijama
                posvećenim mentalnom zdravlju. Također, surađujem s Hrvatskom
                udrugom dula kao volonter i edukant sam psihoterapije pod
                supervizijom, te volontiram u udruzi Amazonas i u društvu za socijalnu podršku.
              </p>
              <p>
                Na osobnoj razini, ponosna sam supruga i majka male djevojčice.
                Karakteriziraju me društvenost, vesela narav i optimizam. U
                slobodno vrijeme uživam u putovanjima s prijateljima, plivanju i
                odmoru na plaži. San mi je imati kamenu kućicu uz more, dok
                kišno vrijeme zaobilazim u širokom luku.
              </p>
              <p>
                Radujem se što ću imati priliku raditi s vama i pružiti podršku
                na vašem putu ka boljem mentalnom zdravlju.
              </p>
            </div>
          </div>
        </section>

        {/* PRISTUP PSIHOTERAPIJI */}
        <section>
          <div className="therapy">
            <div className="title">
              <h2>Metode i pristupi</h2>
              <h1>Transakcijska analiza</h1>
            </div>
            <div className="content">
              <p>
                Transakcijska analiza je multidisciplinarna teorija osobnosti
                koja kombinira elemente psihologije, sociologije i filozofije u
                nastojanju da pruži dublje razumijevanje ljudskog ponašanja. Ako
                ste se ikada pitali: “Zašto ponavljam ponašanje koje mi šteti,
                poput vikanja u prometu ili zatvaranja u sebe tijekom svađe?”,
                transakcijska analiza vam može pružiti odgovore.
              </p>
              <p>
                Metodologija ove teorije fokusira se na analizu načina
                komunikacije između vas i drugih ljudi, kako reagirate u
                različitim situacijama, te kako ti faktori utječu na vašu ukupnu
                dobrobit. Kroz ovakvu vrstu osvješćivanja, transakcijska analiza
                omogućuje vam da razumijete podrijetlo i dinamiku vlastitih
                postupaka.
              </p>
              <p>
                Osim toga, transakcijska analiza razmatra i utjecaj prošlih
                iskustava, posebno tijekom formativnih godina odrastanja. To
                pomaže u identifikaciji obrazaca koji su oblikovali vaše
                trenutačne vrijednosti, uvjerenja i prihvaćanja ili odbacivanja
                određenih aspekata u životu. Na taj način, teorija ne samo da
                vam pomaže razumjeti sebe, već vam i daje alate za prepoznavanje
                i mijenjanje neželjenih obrazaca ponašanja.
              </p>
              <p>
                Transakcijska analiza je također iznimno pristupačan i razumljiv
                alat za savjetovanje i obrazovanje. Njena metodologija i
                terminologija su osmišljeni tako da budu lako shvatljivi, što je
                čini izuzetno korisnim resursom za stručnjake u području
                mentalnog zdravlja, ali i za svakoga tko je zainteresiran za
                osobni razvoj.
              </p>
              <p>
                U konačnici, transakcijska analiza nudi okvir za bolje
                razumijevanje sebe i drugih, čime se otvaraju mogućnosti za
                konstruktivnije i zdravije međuljudske odnose, kao i za osobni
                rast i razvoj.
              </p>
            </div>
          </div>
        </section>

        {/* ETIKA I POVJERLJIVOST */}
        <section>
          <div className="ethics">
            <h1>Etika i povjerljivost</h1>
            <div className="content">
              <h3>
                Osnovni <br /> principi
              </h3>
              <div className="text">
                <p>
                  Posvećeni smo etičkom postupanju i zaštiti vaše privatnosti.
                  Naši terapeuti su obvezni slijediti profesionalne smjernice i
                  zakonske norme kako bi osigurali visoku razinu integriteta i
                  povjerljivosti.
                </p>
              </div>
            </div>
            <div className="content">
              <div className="text">
                <p>
                  Svi vaši osobni i medicinski podaci pohranjuju se na sigurnim
                  serverima i dostupni su samo ovlaštenom osoblju. U skladu s
                  zakonima o zaštiti podataka, vaši podaci nikada neće biti
                  dijeljeni s trećim stranama bez vašeg izričitog pristanka.
                </p>
              </div>
              <h3>
                Povjerljivost <br /> podataka
              </h3>
            </div>
            <div className="content">
              <h3>
                Povjerljivost <br /> procesa
              </h3>
              <div className="text">
                <p>
                  Sve što se dijeli tijekom terapijskih sesija ostaje
                  povjerljivo. Vaš terapeut će o vašem slučaju diskutirati samo
                  u profesionalnom kontekstu i samo ako je to nužno za vašu
                  skrb.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* NEWS */}
        <News />
      </div>
    </>
  );
};

export default AboutUs;
