import './style.scss';

import { useState } from 'react';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';

const Contact = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });

  const sendEmail = () => {
    emailjs
      .send(
        'service_p7ry0ei',
        'template_g9rokmv',
        formData,
        'EoWyFpKc4qEnDa3Ly'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    navigate('/poruka-poslana', { replace: true });
  };

  return (
    <div className="contact">
      <h2>Kontaktirajte nas</h2>
      <form onSubmit={sendEmail}>
        <div className="row">
          <input
            type="text"
            placeholder="ime*"
            name="name"
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            required={true}
          />
          <input
            type="tel"
            name="phone"
            placeholder="mobitel"
            onChange={(e) =>
              setFormData({ ...formData, phone: e.target.value })
            }
          />
        </div>
        <input
          type="email"
          name="email"
          placeholder="email*"
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          required={true}
        />
        <textarea
          name="message"
          rows="3"
          placeholder="poruka*"
          onChange={(e) =>
            setFormData({ ...formData, message: e.target.value })
          }
          required={true}
        />
        <button type="submit">pošalji</button>
      </form>
    </div>
  );
};

export default Contact;
