import React from 'react';

const PrivacyPage = () => {
  return (
    <div className="legalPage">
      <h1>Politika privatnosti</h1>
      <p>
        Dobrodošli na našu web stranicu. Vaša privatnost je od izuzetne važnosti
        za nas, a ovaj dokument objašnjava kako prikupljamo, koristimo i čuvamo
        vaše osobne podatke.
      </p>
      <h2>Prikupljanje Informacija</h2>
      <p>
        Kada posjetite našu web stranicu, automatski prikupljamo određene
        informacije o vašem uređaju, uključujući podatke o vašem web
        pregledniku, IP adresi, vremenskoj zoni i nekim kolačićima koji su
        instalirani na vašem uređaju. Također prikupljamo informacije o
        pojedinim web stranicama koje posjećujete na našem web mjestu.
      </p>
      <h2>Prikupljanje Informacija</h2>
      <p>
        Kada posjetite našu web stranicu, automatski prikupljamo određene
        informacije o vašem uređaju, uključujući podatke o vašem web
        pregledniku, IP adresi, vremenskoj zoni i nekim kolačićima koji su
        instalirani na vašem uređaju. Također prikupljamo informacije o
        pojedinim web stranicama koje posjećujete na našem web mjestu.
      </p>
      <h2>Korištenje Informacija</h2>
      <p>
        Koristimo prikupljene informacije na nekoliko načina:
        <ul>
          <li>Za pružanje i održavanje naših usluga</li>
          <li>
            Za analiziranje kako koristite web stranicu kako bismo je mogli
            poboljšati
          </li>
          <li>
            Za komunikaciju s vama, uključujući odgovore na vaša pitanja i
            obavijesti o novim sadržajima ili uslugama
          </li>
        </ul>
      </p>
      <h2>Dijeljenje Vaših Osobnih Informacija</h2>
      <p>
        Vaši podaci su sigurni s nama i nikada nećemo prodati ili dijeliti vaše
        osobne informacije s trećim stranama za njihove promotivne svrhe.
      </p>
      <h2>Sigurnost Podataka</h2>
      <p>
        Primjenjujemo razne mjere sigurnosti kako bismo zaštitili vaše osobne
        informacije. Međutim, nijedna metoda prijenosa podataka putem interneta
        ili metoda elektroničkog pohranjivanja nije 100% sigurna, stoga ne
        možemo garantirati apsolutnu sigurnost vaših podataka.
      </p>
      <h2>Prava Korisnika</h2>
      <p>
        U skladu s važećim zakonodavstvom, imate pravo pristupa osobnim
        informacijama koje posjedujemo o vama, kao i pravo na ispravak,
        ažuriranje ili brisanje tih informacija.
      </p>
      <h2>Kontakt Informacije</h2>
      <p>
        Za više informacija o našim praksama privatnosti, ako imate pitanja, ili
        ako želite uložiti prigovor, molimo vas da nas kontaktirate putem
        e-maila, telefona ili putem kontakt obrasca na našoj web stranici.
      </p>
    </div>
  );
};

export default PrivacyPage;
