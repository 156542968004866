import '../style.scss';
import { Link } from 'react-router-dom';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import News from '../../../components/News';

import clanak1 from '../../../assets/clanak1.webp';
import { Helmet } from 'react-helmet';

const Article1 = () => {
  return (
    <>
      <Helmet>
        <title>
          Kako prepoznati anksioznost: Simptomi i rješenja | Mentalni Kompas
        </title>
      </Helmet>

      <div className="articlePage">
        <div className="articlePageHeader">
          <Link to="/novosti">
            <CircleRoundedIcon /> natrag
          </Link>
          <h1>Kako prepoznati anksioznost: Simptomi i rješenja</h1>
          <div className="articleFeaturedImage">
            <img src={clanak1} alt="Anksioznost" />
          </div>
        </div>
        <div className="article">
          <div className="articleInfo">
            <div className="infoRow">
              <p>
                Autor: <span>Jasmina Zoka</span>
              </p>
            </div>
            <div className="horizontalLine">|</div>
            <div className="infoRow">
              <p>
                Vrijeme čitanja: <span>4min</span>{' '}
              </p>
            </div>
            <div className="horizontalLine">|</div>
            <div className="infoRow">
              <p>
                <span>5.9.2023.</span>
              </p>
            </div>
          </div>

          <div className="articleContent">
            <p>
              Anksioznost je pojam s kojim se mnogi od nas susreću, ali ga
              rijetko tko u potpunosti razumije. Koliko god se činilo da je ovaj
              osjećaj neminovan dio modernog života, važno je znati kako ga
              prepoznati i upravljati njime. U ovom ćemo članku govoriti o
              simptomima anksioznosti i pristupima za njeno ublažavanje ili
              liječenje.
            </p>

            <h2>Što je Anksioznost?</h2>
            <p>
              Anksioznost je normalna i često zdrava emocija. Postaje
              problematična kada se pretvori u anksiozni poremećaj, što može
              utjecati na svakodnevni život. Razlikuje se od straha, koji je
              emocionalni odgovor na stvarnu ili percipiranu neposrednu
              prijetnju, dok je anksioznost očekivanje buduće prijetnje.
            </p>

            <h2>Simptomi Anksioznosti</h2>
            <p>
              Simptomi anksioznosti mogu varirati od osobe do osobe, ali neki od
              najčešćih uključuju:
            </p>
            <h3>Fizički Simptomi</h3>
            <ul>
              <li>Palpitacije srca</li>
              <li>Znojenje</li>
              <li>Drhtanje ili tresenje</li>
              <li>Osjećaj gušenja ili nedostatka zraka</li>
              <li>Bol ili nelagoda u prsima</li>
            </ul>
            <h3>Psihološki Simptomi</h3>
            <ul>
              <li>Osjećaj nervoze ili napetosti</li>
              <li>Osjećaj neposredne opasnosti, panike ili propasti</li>
              <li>Neumjereni strah od svakodnevnih situacija</li>
              <li>Nesposobnost da se ostane smiren i miran</li>
            </ul>
            <h3>Bihevioralni Simptomi</h3>
            <ul>
              <li>Izbjegavanje situacija koje izazivaju anksioznost</li>
              <li>Komplikacije u socijalnim odnosima</li>
              <li>Poteškoće u koncentraciji i donošenju odluka</li>
            </ul>
            <h2>Kako Prepoznati Anksioznost?</h2>
            <p>
              Kako bismo znali da li se radi o 'normalnoj' anksioznosti ili
              anksioznim poremećajima, važna je stručna dijagnostika. Osim
              razgovora sa stručnjakom, moguće je provesti i razne testove i
              upitnike, koji mogu pomoći u dijagnostičkom procesu.
            </p>
            <h3>Različite Vrste Anksioznih Poremećaja</h3>
            <p>
              Treba napomenuti da anksioznost može biti simptom različitih vrsta
              anksioznih poremećaja, kao što su:
            </p>
            <ul>
              <li>Generalizirani anksiozni poremećaj</li>
              <li>Panični poremećaj</li>
              <li>Socijalna anksioznost</li>
              <li>Specifične fobije</li>
              <li>Posttraumatski stresni poremećaj (PTSP)</li>
              <li>Opsesivno-kompulzivni poremećaj (OKP)</li>
            </ul>
            <h2>Rješenja</h2>
            <h3>Psihoterapija</h3>
            <p>
              Najčešće se anksioznost liječi putem psihoterapije, često
              kognitivno-bihevioralnim pristupom. U ovom tipu terapije, cilj je
              identificirati i promijeniti destruktivne ili negativne obrasce
              mišljenja i ponašanja.
            </p>
            <h3>Lijekovi</h3>
            <p>
              Lijekovi poput antidepresiva i anksiolitika često se koriste u
              liječenju anksioznosti. Važno je uzimati lijekove samo po
              preporuci liječnika i pratiti upute za doziranje.
            </p>

            <h2>Tko je najpodložniji anksioznim poremećajima?</h2>
            <p>
              Anksiozni poremećaji ne biraju dob, spol, ili socioekonomske
              uvjete, ali određeni faktori mogu povećati rizik. Ti faktori
              uključuju obiteljsku povijest mentalnih poremećaja, osobne traume,
              i čak određene osobine ličnosti.
            </p>
            <h2>Zašto je važno rano prepoznavanje?</h2>
            <p>
              Neliječena anksioznost može dovesti do niza komplikacija u životu,
              uključujući:
            </p>
            <ul>
              <li>Problemi sa zdravljem</li>
              <li>Poteškoće u školi ili na poslu</li>
              <li>Problemi u interpersonalnim odnosima</li>
              <li>Smanjenje kvalitete života</li>
              <li>Rizik od razvoja drugih mentalnih poremećaja</li>
            </ul>
            <h2>Savjeti i strategije za suočavanje sa anksioznošću</h2>
            <h3>Vježbanje Svjesnosti (Mindfulness)</h3>
            <p>
              Jedan od načina kako možete kontrolirati anksioznost je kroz
              praksu svjesnosti, gdje se fokusirate na sadašnji trenutak,
              umjesto da brinete o budućnosti ili razmišljate o prošlim
              događajima.
            </p>
            <h3>Tehnike Disanja</h3>
            <p>
              Vježbe disanja poput "4-7-8" tehnike mogu pomoći u smirivanju
              simptoma anksioznosti. Ideja je udahnuti kroz nos dok mentalno
              brojite do četiri, zadržati dah dok brojite do sedam, i izdahnuti
              kroz usta dok brojite do osam.
            </p>
            <h2>Postoji li 'izlječenje'?</h2>
            <p>
              Iako anksioznost može biti dugotrajna borba, mnogi ljudi uspješno
              kontroliraju svoje simptome uz pravilan tretman i promjene u
              životnom stilu. Neke osobe smatraju da se njihova anksioznost
              smanjuje ili čak nestaje tijekom vremena.
            </p>
            <h2>Kada potražiti pomoć?</h2>
            <p>
              Ako osjećate da vas anksioznost sprječava u normalnom
              funkcioniranju i uživanju u životu, vrijeme je da potražite
              stručnu pomoć. Ne ignorirajte simptome i ne čekajte da "prođe samo
              od sebe". Liječenje je najučinkovitije kada se započne što ranije.
            </p>

            <p>
              Anksioznost je sveprisutan problem koji može imati ozbiljne
              posljedice po naš život i blagostanje. Prepoznavanje simptoma i
              potraživanje stručne pomoći prvi su koraci ka upravljanju ovim
              stanjem.
            </p>
            <p>
              Važno je znati da niste sami i da postoji niz rješenja koja mogu
              pomoći. Ako mislite da patite od anksioznosti, najbolje je da
              potražite stručnu pomoć. Ovo je jedini način da se postavi točna
              dijagnostika i odredi najučinkovitiji tretman za vas.
            </p>
          </div>
        </div>
        <div className="articleFooter">
          <Link to="/novosti" className="articleFooter-btn">
            Više članaka
          </Link>
          {/* <h1 style={{ textAlign: 'center', lineHeight: 1.2 }}>
          Možda će vas zanimati
        </h1> */}
        </div>
      </div>
    </>
  );
};

export default Article1;
