import { Link } from 'react-router-dom';
import './style.scss';
import { Helmet } from 'react-helmet';

// import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
// import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import News from '../../components/News';
import Contact from '../../components/Contact';

// IMAGES
import header from '../../assets/naslovnaHeader.webp';
import naslovna1 from '../../assets/naslovna1.webp';
// import naslovna2 from '../../assets/naslovna2.webp';
// import naslovna3 from '../../assets/naslovna3.webp';
import naslovna4 from '../../assets/naslovna4.webp';
import jasmina2 from '../../assets/jasmina3.jpg'
import jasmina3 from '../../assets/jasmina1.jpeg'

const HomePage = () => {
  return (
    <>
    <Helmet>
      <title>Mentalni Kompas | Mentalno savjetovanje</title>
    </Helmet>
      <div className="homePage">
        <header>
          <img src={header} alt="Sretni ljudi" />
          <div className="overlay"></div>
          <div className="content">
            <p>„Problemi nas ne bi trebali zaustavljati. Oni su samo način na koji  nam priroda govori da bismo trebali istražiti drukčiji put“</p>
            <h4>- James Norbury</h4>
          </div>
        </header>

        {/* QUOTE */}
        <section>
          <div className="quote">
            <p>
              "Često je hrabrije suočiti se sa svojim strahovima nego ih tjerati
              da vam upravljaju životom."{' '}
            </p>
            <h4>- Albert Ellis, psiholog</h4>
          </div>
        </section>

        {/* MISSION */}
        <section>
          <div className="mission">
            <div className="container">
              <img src={naslovna1} alt="Mentalno savjetovanje" />
            </div>
            <div className="container">
              <p>
                Moja misija je pružiti podršku i vodstvo osobama na njihovom
                putu prema emocionalnom zdravlju, omogućujući im da otkriju
                unutarnju snagu, razviju pozitivne strategije suočavanja te
                ostvare dublje razumijevanje sebe i svijeta oko sebe.
              </p>
            </div>
          </div>
        </section>

        {/* SERVICES */}
        <section>
          <div className="services">
            <div className="service">
              <div className="content">
                <h3>
                  Individualna <br /> savjetovanja
                </h3>
                <p>
                  Individualna savjetovanja mogu pomoći pojedincima u suočavanju
                  s različitim psihološkim poteškoćama i razvijanju emocionalne
                  dobrobiti.
                </p>
                <Link to="usluge">Saznaj više</Link>
              </div>
              <div className="image">
                <img src={jasmina3} alt="Individualno savjetovanje" />
              </div>
            </div>
            <div className="service">
              <div className="image">
                <img src={jasmina2} alt="Online savjetovanje" />
              </div>
              <div className="content">
                <h3>
                  Online <br /> savjetovanja
                </h3>
                <p>Terapeutski razgovori iz udobnosti vašeg doma.</p>
                <Link to="usluge">Saznaj više</Link>
              </div>
            </div>
            <div className="service">
              <div className="content">
                <h3>
                  Poslovna <br /> savjetovanja
                </h3>
                <p>
                  Poslovnim savjetovanjem omogućujemo vašem timu da razvije
                  emocionalnu inteligenciju, poboljša komunikaciju, upravlja
                  stresom i konfliktima te postigne veću produktivnost.
                </p>
                <Link to="usluge">Saznaj više</Link>
              </div>
              <div className="image">
                <img src={naslovna4} alt="Poslovno savjetovanje" />
              </div>
            </div>
          </div>
        </section>

        {/* APPROACH */}
        <section>
          <div className="approach">
            <p>
              Razumijemo da je svaka osoba jedinstvena, s jedinstvenim potrebama
              i izazovima. Stoga pristupamo svakom klijentu individualno,
              kreirajući plan tretmana koji je prilagođen njegovim ili njenim
              specifičnim potrebama.
            </p>
          </div>
        </section>

        {/* ABOUT  */}
        <section>
          <div className="about">
            <div className="content">
              <h1>O meni</h1>
              <p>
                Ja sam Jasmina i zalažem se za mentalno zdravlje i
                dobrobit. Osim što sam aktivan član relevantnih organizacija kao
                što su UTA, EATA i ITAA, vodim i vlastito savjetovalište gdje
                pružam sigurno okruženje za sve one koji traže pomoć. Također, volontiram u udruzi Amazonas i u društvu za socijalnu podršku. Na osobnoj
                razini, moj život obogaćuju obitelj, prijatelji i razni
                interesi, poput putovanja i plivanja. Veselim se prilici da vas
                podržim na vašem putu ka boljem mentalnom zdravlju.
              </p>
            </div>
            {/* <div className="image">
            <img
              src="https://media-private.canva.com/FHB34/MAEI0qFHB34/1/s.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJWF6QO3UH4PAAJ6Q%2F20230831%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20230831T144716Z&X-Amz-Expires=81208&X-Amz-Signature=bcce93be08bb88c0f108cfdce1dffa18159271938d97ef2ff16933def34b9634&X-Amz-SignedHeaders=host&response-expires=Fri%2C%2001%20Sep%202023%2013%3A20%3A44%20GMT"
              alt=""
            />
          </div> */}
          </div>
        </section>

        {/* REVIEWS */}
        {/* <section>
        <div className="reviews">
          <h2>Klijenti o nama</h2>
          <div className="carousel">
            <button className="nav">
              <ChevronLeftRoundedIcon />
            </button>
            <div className="carouselContainer">
              <p>
                "Posjećivala sam ovu psihoterapijsku praksu već nekoliko mjeseci
                i rezultati su izvanredni. Kroz cijeli proces osjećala sam se
                saslušanom i poštovanom. Jasmina je stručna i puna
                razumijevanja, pružajući mi vrijedne alate kako bih se suočila s
                izazovima u mom životu. Sada se osjećam znatno opuštenije i imam
                mnogo jasniju sliku o tome kako urediti svoj život. Apsolutno
                preporučujem!"
              </p>
              <h4>Ivana, 42 godine</h4>
            </div>
            <div className="carouselContainer">
              <p>
                "Posebno mi je pomogla fleksibilnost u terminima i mogućnost
                online konzultacija, budući da često putujem zbog posla. Jasmina
                je profesionalna i pristupačna, ali osjetio sam da smo u početku
                trebali više vremena da uspostavimo pravu dinamiku. S vremenom
                se to popravilo i sada sam vrlo zadovoljan postignućima.
                Preporučio bih usluge ove prakse svakome tko traži kvalitetnu
                psihoterapiju."
              </p>
              <h4>Marko, 29 godina</h4>
            </div>
            <button className="nav">
              <ChevronRightRoundedIcon />
            </button>
          </div>
        </div>
      </section> */}

        {/* NEWS */}
        <News />

        {/* CONTACT  */}
        <Contact />
      </div>
    </>
  );
};

export default HomePage;
