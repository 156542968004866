import { Link, NavLink } from 'react-router-dom';
import './style.scss';

import logo from '../../assets/mentalniKompas_logo_white.png';
import backgroundImg from '../../assets/menuImg.png'
import { useState } from 'react';

const Navbar = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <nav>
      {/* <div className="announcementBar">
        <p>Popunite obrazac za besplatne 15 minutne konzultacije</p>
      </div> */}
      <div className="navbar">
        <Link to="/" className="logo">
          <img src={logo} alt="Mentalni Kompas" />
        </Link>
        <div className="links">
          <NavLink to="/">Početna</NavLink>
          <NavLink to="usluge">Usluge</NavLink>
          <NavLink to="o-nama">O meni</NavLink>
          <NavLink to="prvi-korak">Prvi korak</NavLink>
          <NavLink to="novosti">Novosti</NavLink>
        </div>
        <div
          className="circle"
          style={
            openModal
              ? { backgroundColor: 'transparent' }
              : {
                  backgroundColor: 'var(--tertiary)',
                  transitionDelay: openModal ? '0' : '0.8s',
                }
          }
        >
          <div className="menuBtn" onClick={() => setOpenModal(!openModal)}>
            <div
              className="line"
              style={{
                width: '70%',
                backgroundColor: openModal ? 'var(--white)' : '',
                transitionDelay: openModal ? '0s' : '0.6s',
              }}
            ></div>
            <div
              className="line"
              style={{
                width: '85%',
                backgroundColor: openModal ? 'var(--white)' : '',
                transitionDelay: openModal ? '0s' : '0.6s',
              }}
            ></div>
            <div
              className="line"
              style={{
                width: '100%',
                backgroundColor: openModal ? 'var(--white)' : '',
                transitionDelay: openModal ? '0s' : '0.6s',
              }}
            ></div>
          </div>
        </div>
      </div>
      <div
        className="menuOverlay"
        style={{
          right: openModal ? '0' : '-100vw',
          transitionDelay: openModal ? '0s' : '0.25s',
        }}
      >
        <div className="backgroundImage">
          <img src={backgroundImg} alt="Mentalni Kompas" />
        </div>
        <ul className="menuLinks">
          <li className="menuItem">
            <NavLink
              to="/"
              onClick={() => setOpenModal(!openModal).window.scrollTo(0, 0)}
              style={{
                top: openModal ? '0' : '100px',
                transitionDelay: openModal ? '0.4s' : '0s',
              }}
            >
              <span>Početna</span>
            </NavLink>
            <div className="menuItem-wrapper"></div>
          </li>
          <li className="menuItem">
            <NavLink
              to="usluge"
              onClick={() => setOpenModal(!openModal).window.scrollTo(0, 0)}
              style={{
                top: openModal ? '0' : '100px',
                transitionDelay: openModal ? '0.5s' : '0s',
              }}
            >
              <span>Usluge</span>
            </NavLink>
            <div className="menuItem-wrapper"></div>
          </li>
          <li className="menuItem">
            <NavLink
              to="o-nama"
              onClick={() => setOpenModal(!openModal).window.scrollTo(0, 0)}
              style={{
                top: openModal ? '0' : '100px',
                transitionDelay: openModal ? '0.6s' : '0s',
              }}
            >
              <span>O meni</span>
            </NavLink>
            <div className="menuItem-wrapper"></div>
          </li>

          <li className="menuItem">
            <NavLink
              to="prvi-korak"
              onClick={() => setOpenModal(!openModal).window.scrollTo(0, 0)}
              style={{
                top: openModal ? '0' : '100px',
                transitionDelay: openModal ? '0.8s' : '0s',
              }}
            >
              <span>Prvi korak</span>
            </NavLink>
            <div className="menuItem-wrapper"></div>
          </li>
          <li className="menuItem">
            <NavLink
              to="novosti"
              onClick={() => setOpenModal(!openModal).window.scrollTo(0, 0)}
              style={{
                top: openModal ? '0' : '100px',
                transitionDelay: openModal ? '0.9s' : '0s',
              }}
            >
              {' '}
              <span>Novosti</span>
            </NavLink>
            <div className="menuItem-wrapper"></div>
          </li>
        </ul>

       
      </div>
    </nav>
  );
};

export default Navbar;
