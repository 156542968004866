import './style.scss';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Faq = () => {
  return (
    <div className="faq">
      <h1>Česta pitanja</h1>
      <div className="row">
        <Accordion sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Kako mogu zakazati termin?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Molimo vas da nas kontaktirate putem telefona, e-maila ili putem
              online obrasca na našoj web stranici za zakazivanje prvog termina.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>

      <div className="row">
        <Accordion sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              Je li transakcijska analiza korisna za učinkovitu komunikaciju?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Apsolutno, transakcijska analiza se smatra jednim od najmoćnijih
              alata za unapređenje komunikacijskih vještina. Kroz različite
              tehnike i koncepte, poput analize "Ego stanja" (Roditelj, Odrasli,
              Dijete) i identifikacije "transakcijskih skriptova," klijenti mogu
              dublje razumjeti kako i zašto komuniciraju na određeni način. Ovo,
              zauzvrat, omogućuje im da prepoznaju ne samo svoje vlastite
              obrasce komuniciranja, nego i obrasce onih s kojima komuniciraju.
              Primjerice, razumijevanje koja Ego stanja su aktivna u određenim
              situacijama može pomoći osobi da shvati zašto određene interakcije
              izazivaju konflikte ili nesporazume. Kroz ovu svijest, osoba može
              aktivno mijenjati svoj komunikacijski pristup, birajući strategije
              koje su više u skladu s željenim ishodima. Osim toga,
              transakcijska analiza pomaže u razumijevanju "igara" koje ljudi
              igraju u međuljudskim odnosima, što su često nesvjesni obrasci
              koji ometaju zdravu komunikaciju. Prepoznavanje tih igara i
              njihovo svjesno izbjegavanje mogu voditi ka otvorenijem i
              iskrenijem dijalogu. U konačnici, transakcijska analiza može
              pomoći pojedincima da postanu svjesniji svojih komunikacijskih
              stilova, da prepoznaju i promijene negativne obrasce, te da
              razviju kompetencije koje vode ka učinkovitijoj i zdravijoj
              komunikaciji. Ovo ne samo da doprinosi boljim međuljudskim
              odnosima, već i povećava opću kvalitetu života.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="row">
        <Accordion sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              Kako izgleda transakcijska analiza u psihologiji?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Transakcijska analiza je jedan od ključnih pristupa u modernoj
              psihoterapiji, koji terapeutima omogućuje dublje istraživanje
              klijentove osobnosti i njegovih životnih odluka. U terapiji,
              psihoterapeut koristi niz specifičnih tehnika, uključujući "Ego
              stanja" (Roditelj, Odrasli, Dijete), za analizu klijentove
              unutarnje dinamike i komunikacijskih stilova. Jedan od prvih
              koraka u terapeutskom procesu je dijagnostička faza, gdje terapeut
              zajedno s klijentom razmatra životne događaje, odluke i obrazce
              ponašanja koji su oblikovali njegovu osobnost. Ovo omogućuje
              klijentu i terapeutu da zajedno identificiraju ključne probleme i
              izazove s kojima se klijent suočava. Transakcijska analiza je
              posebno korisna u identifikaciji i analizi nesvjesnih procesa koji
              često diktiraju klijentove reakcije i odluke. Kroz metode poput
              "ponovnog oživljavanja" (re-experiencing) i introspekcije,
              terapeut pomaže klijentu da verbalizira i razumije duboko
              ukorijenjena uvjerenja i emocionalne odgovore koje možda ranije
              nije bio sposoban artikulirati ili prepoznati. Jedan od ključnih
              aspekata terapije putem transakcijske analize je i rad na
              osvještavanju negativnih emocija i reakcija. Kroz ovaj proces,
              klijenti se uče kako preuzeti odgovornost za svoje postupke,
              umjesto da ih pripisuju vanjskim okolnostima ili drugim osobama.
              Ovo pridonosi razvoju autonomije i većeg kapaciteta za donošenje
              zdravijih životnih izbora. U konačnici, transakcijska analiza nudi
              holistički okvir za razumijevanje klijenta, što omogućuje
              terapeutu da koristi prilagođene strategije i tehnike za podršku
              klijentu u njegovom putu prema samorazumijevanju i osobnom
              razvoju.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="row">
        <Accordion sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Kako vam transakcijska analiza može pomoći?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Transakcijska analiza je izuzetno korisna za osobni razvoj jer
              potiče samosvijest i preuzimanje odgovornosti za vlastiti život.
              Također se pokazuje efikasnom u različitim kontekstima,
              uključujući obrazovanje, poslovni svijet i timsku dinamiku, gdje
              nudi snažne strategije za poboljšanje komunikacije i kohezije.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default Faq;
