import { Link } from 'react-router-dom';
import News from '../../components/News';
import './style.scss';

import clanak1 from '../../assets/clanak1.webp';
import clanak2 from '../../assets/clanak2.webp';
import { Helmet } from 'react-helmet';

const NewsPage = () => {
  return (
    <>
      <Helmet>
        <title>Novosti | Mentalni Kompas</title>
      </Helmet>

      <div className="newsPage">
        <h1>Novosti</h1>
        {/* <News/> */}

        <div className="container">
          <Link
            to="/novosti/kako-prepoznati-anksioznost-simptomi-i-rjesenja"
            className="newsContainer"
          >
            <div className="image">
              <img src={clanak1} alt="Anksioznost" />
            </div>
            <div className="title">
              <p>Kako prepoznati anksioznost: Simptomi i rješenja</p>
            </div>
          </Link>

          <Link
            to="/novosti/ucinkovite-tehnike-opustanja-savjeti-za-bolje-mentalno-zdravlje"
            className="newsContainer"
          >
            <div className="image">
              <img src={clanak2} alt="Meditacija" />
            </div>
            <div className="title">
              <p>
                Učinkovite tehnike opuštanja: Savjeti za bolje mentalno zdravlje
              </p>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default NewsPage;
